<template>
  <div class="cloudBody">
    <!--    <div style="float: left;">-->
    <!--      <img style="position: absolute;left: 480px;top: 320px;width: 100px;height: 100px;" src="/static/images/cloud/alarmspot.gif"/>-->
    <!--    </div>-->
    <div class="rightContent">
      <div class="numContent">
        <div class="numLeft">
          251659
        </div>
        <div class="numRight">
          207385
        </div>
      </div>
      <div class="middleContent">
        <div class="middleLeft">
          美裕路行政楼一号梯
        </div>
        <div class="middleRight">
        </div>
      </div>
      <div class="waveContent">
        <el-carousel indicator-position="none" :interval="6000" height="72vh">
          <el-carousel-item v-for="item in 3" :key="item">
            <div v-if="item === 1" class="chart1_1">
            </div>
            <div v-if="item === 1" class="chart1_2">
            </div>
            <div v-if="item === 1" class="chart1_3">
            </div>
            <div v-if="item === 2" class="chart2_1">
            </div>
            <div v-if="item === 2" class="chart2_2">
            </div>
            <div v-if="item === 2" class="chart2_3">
            </div>
            <div v-if="item === 3" class="chart3_1">
            </div>
            <div v-if="item === 3" class="chart3_2">
            </div>
            <div v-if="item === 3" class="chart3_3">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {};
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
.cloudBody {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: url(/static/images/cloud/cloudbg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  text-align: center;
  color: #ffffff;

  .rightContent {
    width: 40%;
    height: 92vh;
    float: right;
    margin-top: 8vh;
    margin-right: 2%;

    .numContent {
      width: 100%;
      height: 12vh;
      margin-bottom: 1vh;
      line-height: 9vh;
      font-size: 7vh;

      .numLeft {
        width: 49%;
        height: 100%;
        float: left;
        background: url(/static/images/cloud/salestotal.png) no-repeat;
        background-size: 100% 100%;
      }

      .numRight {
        width: 49%;
        height: 100%;
        float: right;
        background: url(/static/images/cloud/total.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .middleContent {
      width: 90%;
      height: 5vh;
      margin: 0 auto 1vh;
      line-height: 5vh;
      font-size: 2.6vh;

      .middleLeft {
        width: 69%;
        height: 100%;
        float: left;
        background: url(/static/images/cloud/choiceframe.png) no-repeat;
        background-size: 100% 100%;
      }

      .middleRight {
        width: 29%;
        height: 100%;
        float: right;
        background: url(/static/images/cloud/changebtn.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .waveContent {
      width: 90%;
      height: 72vh;
      margin: 0 auto;
      /*background-color: #5C6576;*/
      .chart1_1 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave1_1.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart1_2 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave1_2.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart1_3 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave1_3.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart2_1 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave2_1.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart2_2 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave2_2.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart2_3 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave2_3.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart3_1 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave3_1.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart3_2 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave3_2.gif) no-repeat;
        background-size: 100% 100%;
      }

      .chart3_3 {
        width: 100%;
        height: 23vh;
        background: url(/static/images/cloud/wave3_3.gif) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
